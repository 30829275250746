.content_main {
    width: 100%;
    background-color: #F4F5F8;
    height: 100%;
    padding: 30px 0 50px 0;
}

.header p, .header h2 {
    margin: 0;
}

.header h2 {
    font-weight: 700;
    font-size: 43px;
    color: #162136;
    display: flex;
    align-items: center;
    /*justify-content: center;*/
    flex-direction: row;
}

.header p {
    font-size: 19px;
    font-weight: 400;
    color: #656C7B;
}

.filtered_box {
    display: flex;
    align-items: center;
    margin-top: 30px;
    position: relative;
}

.border {
    background-color: #000;
    transition: .3s all;
    left: 0;
    bottom: -10px;
    width: 100px;
    height: 3px;
    position: absolute;
    z-index: 99999;
}

.filtered_box p {
    padding: 0;
    margin: 0;
    margin-right: 20px;
    color: #7E8595;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    transition: color .2s, opacity .2s;
    z-index: 2;
}

.filtered_box p:hover {
    color: #039be5;
}

.selected {
    color: #000 !important;
}

.selected:hover {
    color: #000 !important;
}

.items_content {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 50px;
    grid-row-gap: 50px;
}

@media screen and (max-width: 1510px) {
    .items_content {
        grid-template-columns: repeat(3, 1fr);
    }

    .header h2 {
        font-weight: 700;
        font-size: 35px;
        color: #162136;
    }
}

@media screen and (max-width: 1120px) {
    .items_content {
        grid-template-columns: repeat(2, 1fr);
    }

    .content_main {
        padding-top: 20px;
    }

}

@media screen and (max-width: 770px) {
    .items_content {
        grid-template-columns: repeat(1, 1fr);
    }

    .header h2 {
        font-weight: 700;
        font-size: 28px;
        color: #162136;
    }

}

@media screen and (max-width: 475px) {
    .items_content {
        grid-template-columns: repeat(1, 1fr);
    }

    .header h2 {
        font-weight: 700;
        font-size: 22px;
        color: #162136;
    }

    .header p {
        font-size: 16px;
        margin-top: 5px;
    }

    .filtered_box {
        width: 100%;
        /*overflow-y: hidden;*/
        /*padding-bottom: 10px;*/
        margin-top: 20px;
    }

    .filtered_box p {
        white-space: nowrap;
    }

    .filtered_box p {
        font-size: 14px;
    }

}
