.item {
    /*border: .1px solid red;*/
    border-radius: 8px;
    transition: box-shadow .3s ease;
    box-shadow: 0 1px 2px rgb(22 33 54 / 16%);
    padding: 16px;
    cursor: pointer;
    height: 280px;
    width: 320px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    background-color: #fff;
}

.item:hover {
    box-shadow: 0 10px 20px rgb(22 33 54 / 16%);
}

.header_item img {
    height: 40px;
    max-width: 280px;
    margin-bottom: 20px;
}

.header_item2 img {
    height: 60px;
}

.header_item {
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: column-reverse;
    text-align: center;
    width: 100%;
    justify-content: space-between;
}

.img_box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.show {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px !important;
    margin: 0;
    border: 1px solid;
    border-radius: 100%;
    height: 18px;
    width: 18px;
    position: absolute;
    top: -29px;
    background-color: #039BE5;
    color: #fff;
    right: -29px;
    cursor: pointer;
}

.header_item h3 {
    margin: 0;
}

.content_item div {
    display: flex;
    align-items: center;
    width: 100%;

}

.content_item p {
    margin: 0;
}

.content_item2 div {
    display: flex;
    align-items: center;
    width: 100%;
}

.content_item2 p {
    margin: 0;
}

.content_item2 {
    margin-top: -10px;
}

.content_item2 div {
    margin-top: 10px;
}


.answer {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    color: #687082;
    margin-left: 20px !important;
    white-space: nowrap;
}


.text {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    color: #687082;
    white-space: nowrap;
    /*margin: 10px 0 !important;*/
    width: 100%;
}

.qest {
    font-size: 18px;
    font-weight: 600;
    line-height: 16px;
    width: 30%;
    min-width: 30%;
    max-width: 30%;
}

.btn {
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;

    background-color: #039BE5;
    padding: 20px 30px;
    margin: 10px auto 10px auto;

    border-radius: 10px;
    transition: .3s all;
}

.content_item_item {
    margin: 10px auto;
}

.btn span {
    line-height: 0;
    font-size: 19px;
    color: #fff;
    font-weight: 500;
    padding: 0;
    margin-top: -3px;
}

.btn:hover {
    background-color: rgba(3, 155, 229, 0.84);
}

.box_text {
    margin-bottom: 10px;
}


